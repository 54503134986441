<script>
	import { page } from "$app/stores";
	import { hosts } from "src/constants/common/hosts";

	$: hostname = $page.url.hostname;
	$: selectedHost = hosts.find((host) => hostname.includes(host.hostname));
</script>

<footer class="grid gap-5 space-y-5 py-20 md:grid-cols-3 md:pb-0">
	<div class="hidden items-center md:flex">
		<a
			href="https://sites.google.com/txm-methods.com/centraldechamadostxm/início"
			rel="noopener noreferrer"
			target="_blank"
		>
			<button class="mt-10 flex justify-center space-x-4 md:mt-0">
				<div>
					<img alt="" src="/img/footer/icon_atention.svg" />
				</div>
				<p class="text-gray-brand-200">Relatar um problema</p>
			</button>
		</a>
	</div>

	<div class="flex items-center justify-center">
		{#if selectedHost}
			<a class="w-60 object-cover" href={selectedHost.url} target="_blank">
				<img alt={selectedHost.name} src={selectedHost.img} />
			</a>
		{:else}
			<div>
				<a href="https://txm.business/">
					<img alt="" src="/img/footer/logo_txm_footer.svg" />
				</a>
			</div>
		{/if}
	</div>

	<div class="flex items-center justify-end">
		{#if hostname.includes("inovaseed")}
			<a href="https://www.parque.ufrj.br/" target="_blank">
				<img alt="" src="/img/footer/logo_parq_ec_UFRJ.png" />
			</a>
		{/if}
	</div>

	<div class="col-span-3">
		{#if hostname.includes("inpetuhub")}
			<p class="hidden text-center text-neutral-300 md:block">
				Licensed for Mood Lab Design ® 2023 - 2033
			</p>
		{:else}
			<p class="hidden text-center text-neutral-300 md:block">
				Copyright © TXM Business - 2019-2029
			</p>
		{/if}
	</div>
</footer>

<!-- <Modal bind:this={modal} outerClass="w-2/3 items-center ">
	<div class="flex flex-col p-10 w-full h-full modal gap-4">
		<div>
			<h1 class="text-3xl">Relate seu Problema</h1>
			<h2 class="text-xl">Assim que possível verificaremos a melhor forma de resolvê-lo.</h2>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
			>Em qual página da plataforma você estava quando o problema ocorreu?</span
			>
			<input class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
			>Qual foi o problema (o que você estava fazendo e o que não funcionou)?</span
			>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg">Possui algo a mais para relatar?</span>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex justify-end gap-4">
			<button class="button" on:click={modal.close}>Cancelar</button>
			<button class="button">Enviar</button>
		</div>
	</div>
</Modal> -->
